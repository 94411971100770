import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { removeStopwords, eng } from 'stopword';

// third-party
import {
	signInWithPopup,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	signOut,
	sendPasswordResetEmail,
	GoogleAuthProvider,
	getAdditionalUserInfo,
	onAuthStateChanged,
	getAuth
} from 'firebase/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { auth, functions, storage, db } from 'utils/firebaseInitialize';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import {
	getStorage,
	ref,
	uploadBytes,
	uploadBytesResumable,
	getDownloadURL,
	deleteObject
} from 'firebase/storage';
import {
	deleteDoc,
	doc,
	collection,
	getDocs,
	addDoc,
	Timestamp,
	setDoc,
	getDoc,
	updateDoc,
	where,
	query
} from 'firebase/firestore';

// action - state management
import { LOGIN, LOGOUT, UPDATE_USER } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import firebaseConfig from 'utils/firebaseConfig';
import { transformToTimestamp } from 'utils/utilFunctions';
import { getCount, getListQuery } from 'API/basicCalls';

// firebase initialize
if (!firebase.apps.length) {
	firebase.initializeApp(firebaseConfig);
}

// connectFunctionsEmulator(functions, 'localhost', 5001);

const initialState = {
	isLoggedIn: false,
	isInitialized: false,
	user: null
};

const dbStorage = getStorage();
// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext(null);

export const FirebaseProvider = ({ children }) => {
	const [state, dispatch] = useReducer(accountReducer, initialState);
	const [context, setContext] = useState({});
	const navigate = useNavigate();

	useEffect(
		() => {
			login();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch]
	);

	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	];

	//Function used to perform any bulk updates on the database
	const updateDatabase = async () => {
		console.log('getting list');
		const listRef = collection(db, 'opportunity owner');

		// if (list.empty) {
		// 	return [];
		// } else {
		// const data = list.docs.map((doc) => {
		// 	return { id: doc.id, ...doc.data() };
		// });

		// for (const record of data) {
		// 	// const opp = record?.linkedOpportunity?.id;
		// 	// const oppRef = doc(db, 'opportunities', opp);
		// 	// const oppSnap = await getDoc(oppRef);
		// 	// const docRef = doc(db, 'projections', record.id);

		// 	// if (oppSnap.exists()) {
		// 	const recordID = record.id;
		// 	delete record.id;
		// 	delete record.dateCreated;
		// 	delete record.updatedDate;
		// 	record.addedBy = { id: state.user.id, name: state.user.name };
		// 	record.dateCreated = Timestamp.fromDate(new Date());

		// 	console.log({ record });
		// 	await setDoc(doc(db, 'opportunity owner', recordID), record);
		// 	// console.log('updated:', record.id, updateOBJ);
		// 	// } else {
		// 	// 	// docSnap.data() will be undefined in this case
		// 	// 	console.log('No such document!');
		// 	// }
		// }
		// }

		console.log('finished');
	};

	const login = () => {
		onAuthStateChanged(auth, async (user) => {
			if (user) {
				const userData = await getUserProfile(user);
				if (userData === {}) return;
				const {
					id,
					email,
					firstName,
					lastName,
					displayName,
					permissions,
					profilePicture,
					teams,
					showActuals,
					showProjections,
					calendar,
					oppPerformance,
					notifications
				} = userData;

				if (userData && userData != 'error') {
					const userFTES = await getListQuery('projections', 'fte.id', id, '==');
					dispatch({
						type: LOGIN,
						payload: {
							isLoggedIn: true,
							user: {
								...(!notifications && {
									notifications: {
										email: false,
										notifications: false
									}
								}),
								...userData,
								name: displayName || `${firstName} ${lastName}`,

								ftes: userFTES,
								calendar,
								oppPerformance,
								provider: user.providerData[0].providerId
							}
						}
					});
					setContext({ auth: auth.currentUser.accessToken });
				}
			} else {
				dispatch({
					type: LOGOUT
				});
			}
		});
	};

	const firebaseEmailPasswordSignIn = async (email, password) => {
		const result = await signInWithEmailAndPassword(auth, email, password);

		return result;
	};

	const firebaseGoogleSignIn = () => {
		const provider = new GoogleAuthProvider();

		return signInWithPopup(auth, provider);
	};

	const firebaseRegister = async (email, password) => {
		createUserWithEmailAndPassword(auth, email, password);
	};

	const getUserGoogleMeta = (user) => {
		let data = getAdditionalUserInfo(user);

		return data;
	};

	const logout = async () => {
		await signOut(auth);
		navigate('/');
	};

	const resetPassword = async (email) => {
		await sendPasswordResetEmail(auth, email);
	};

	const updateProfile = () => {};

	const updateSettings = (name, newValue) => {
		dispatch({
			type: UPDATE_USER,
			payload: {
				settings: {
					[name]: newValue
				}
			}
		});
		formSubmissionUpdate('users', { id: state.user.id, [name]: newValue });
	};

	const createUser = async (isGoogle, newData, neededData) => {
		const create = httpsCallable(functions, 'create');

		try {
			newData = { newData }.newData;
			let firstName = '';
			let lastName = '';
			let profilePic = '';

			if (isGoogle) {
				neededData = neededData.profile;
				firstName = neededData.given_name;
				lastName = neededData.family_name;
				profilePic = neededData.picture;
			} else {
				neededData = neededData.values;
				firstName = neededData.firstName;
				lastName = neededData.lastName;
				profilePic = '';
			}
			const newUser = {
				email: newData.email,
				firstName: firstName,
				lastName: lastName,
				permissions: ['User', 'Admin'], //Admin permission added for testing purposes only. Will be removed for production live site
				profilePicture: profilePic,
				status: 'Active',
				uid: newData.uid,
				col: 'users',
				companies: [],
				teams: [],
				manager: { email: '', id: '', name: 'No Manager' },
				position: '',
				showActuals: false,
				showProjections: false,
				notifications: {
					email: true,
					notifications: true
				}
			};
			const userData = await create(newUser);
			return userData;
		} catch (err) {
			return 'error';
		}
	};

	const registerNewUser = async (formInfo) => {
		const userCreate = httpsCallable(functions, 'createUser');
		const create = httpsCallable(functions, 'create');

		let expirationDate = new Date('12/31/2030');

		formInfo.col = 'users';
		formInfo.expirationDate = expirationDate;

		let newUserId = '';

		await userCreate(formInfo)
			.then(async (res) => {
				if (res.data.errorInfo) {
					return res.data.errorInfo.message;
				} else {
					formInfo.uid = res.data;

					await create(formInfo).then((res) => {
						newUserId = res.data;
						return res.data;
					});
				}
			})
			.catch((err) => err);

		return newUserId;
	};

	const getUserProfile = async (potentialNewUser) => {
		const getUser = httpsCallable(functions, 'listBySimpleQuery');
		try {
			const data = await getUser({
				col: 'users',
				value: potentialNewUser.uid,
				condition: '==',
				field: 'uid'
			});

			const userData = data.data[0];
			if (userData.length === 0) return {};

			if (data.data.length === 0) {
				const userData = await getListQuery('users', 'email', potentialNewUser.email, '==');

				if (userData.length > 0) {
					if (userData[0].uid !== potentialNewUser.uid) {
						await formSubmissionUpdate('users', {
							id: userData[0].id,
							uid: potentialNewUser.uid
						}).then((res) => {
							// window.location.reload();
							return userData[0];
						});
					} else {
						return {};
					}
				}
			} else return userData;
		} catch (err) {
			return 'error';
		}
	};

	if (state.isInitialized !== undefined && !state.isInitialized) {
		return <Loader />;
	}

	// ADDED FUNCTIONS --------------------------------------------------------------

	const getList = async (col) => {
		const data = httpsCallable(functions, 'list');

		const query = {
			col: col
		};

		const dataList = await data(query);

		return dataList.data;
	};

	const getRecordById = async (col, id) => {
		const data = httpsCallable(functions, 'getRecordById');

		const query = {
			col: col,
			id: id
		};

		const record = await data(query);

		return record;
	};

	const formSubmissionCreate = async (collectionName, newData) => {
		const create = httpsCallable(functions, 'create');

		newData.col = collectionName;
		newData.addedBy = { id: state.user.id, name: state.user.name };

		delete newData._persist;

		const newDoc = await create(newData)
			.then((res) => res)
			.catch((err) => {
				return 'error';
			});

		return newDoc;
	};

	const formSubmissionUpdate = async (collectionToUpdate, newData) => {
		let id = newData.id;
		newData.col = collectionToUpdate;

		for (const [key, value] of Object.entries(newData)) {
			if (key.toLocaleLowerCase().includes('date')) {
				delete newData[key];
			}
		}

		delete newData._persist;

		const update = httpsCallable(functions, 'update');
		await update(newData)
			.then((res) => {})
			.catch((err) => {
				id = 'error';

				return 'error';
			});

		return id;
	};

	const uploadDocument = async (document, path) => {
		let uploadPath = path ? path : 'documents/';
		const metadata = {
			contentType: document.type
		};

		const storageRef = ref(dbStorage, uploadPath + document.name);
		const uploadTask = await uploadBytesResumable(storageRef, document, metadata);

		let url = await getDownloadURL(storageRef);

		return url;
	};

	const uploadImage = async (e, files) => {
		const file = e[0];
		const bucket = files ? 'files' : 'images';

		const storageRef = ref(storage, `${bucket}/${file?.name}`);

		const uploadedFile = await uploadBytes(storageRef, file).then((snapshot) => {
			return snapshot;
		});

		return uploadedFile.metadata;
	};

	// SUBCOLLECTION FUNCTIONS --------------------------------------------------------------
	const addSubCollection = async (col, id, sub, data) => {
		const create = httpsCallable(functions, 'createSubcollection');
		data.col = col;
		data.ref = id;
		data.sub = sub;
		data.addedBy = { id: state.user?.id || '', name: state.user?.name || '' };
		data.dateCreated = new Date();

		const newData = await create(data);

		return newData;
	};

	const getSubCollection = async (col, id, sub) => {
		const subCollection = httpsCallable(functions, 'getSubCollection');

		const data = {
			col,
			id,
			sub
		};

		const dataList = await subCollection(data);
		return dataList.data;
	};

	//DELETE FUNCTIONS ----------------------------------------------------------------

	const deleteDocument = async (col, document) => {
		const storageRef = ref(dbStorage, 'documents/' + document.name);
		await deleteObject(storageRef).then(async () => {
			const collectionToUse = collection(db, col);
			await deleteDoc(doc(collectionToUse, document.id));
		});
	};

	return (
		<FirebaseContext.Provider
			value={{
				...state,
				firebaseRegister,
				firebaseEmailPasswordSignIn,
				login: () => {},
				firebaseGoogleSignIn,
				logout,
				resetPassword,
				getUserGoogleMeta,
				createUser,
				getUserProfile,
				updateProfile,
				getList,
				getRecordById,
				getListQuery,
				formSubmissionCreate,
				formSubmissionUpdate,
				uploadDocument,
				getSubCollection,
				addSubCollection,
				deleteDocument,
				registerNewUser,
				updateDatabase,
				uploadImage,
				updateSettings
			}}
		>
			{children}
		</FirebaseContext.Provider>
	);
};

FirebaseProvider.propTypes = {
	children: PropTypes.node
};

export default FirebaseContext;
